import badass from './bm.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={badass} className="App-logo" alt="logo" />
        <p>
          hello <code>unnervik.se</code>
        </p>
      </header>
    </div>
  );
}

export default App;
